import * as amplitude from '@amplitude/analytics-browser'
import { type LoaderFunctionArgs, json } from '@remix-run/node'
import { Outlet, useLoaderData, useLocation } from '@remix-run/react'
import { type ISbStories, getStoryblokApi } from '@storyblok/react'
import { parse as parseCookie } from 'cookie'
import { motion, useMotionValueEvent, useScroll } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'

import AnnouncementBanner from '#app/components/announcement-banner'
import { Footer } from '#app/components/navigation/footer'
import Navbar from '#app/components/navigation/navbar'
import { WhyShopHere } from '#app/components/why-shop-here'
import { type UTMParams } from '#app/constants/utm-types'
import { getVisitor } from '#app/cookies/visitor.server'
import useMediaQuery from '#app/hooks/useMediaQuery'
import { getUserId } from '#app/server/auth.server.ts'
import { getProductsOnSale } from '#app/server/clerk/plp.server'
import { isPreview } from '#app/utils/isPreview.tsx'
import { languageSession } from '#app/utils/localisation/lang.server'
import {
	getLanguageFromCookieOrBrowserOrFallback,
	isAcceptedLanguage,
} from '#app/utils/localisation/lokalisation-utilities'
import { type AnnoncementStoryblok, type NavigationCategoriesStoryblok } from '#types/component-types-sb'

export async function loader({ request }: LoaderFunctionArgs) {
	const storyBlokApi = getStoryblokApi()
	const visitorId = await getVisitor(request)
	const langSession = await languageSession.getSession(
		request.headers.get('cookie'),
	)

	// Set language to Danish by default, or get it from the cookie/browser
	let language = getLanguageFromCookieOrBrowserOrFallback(
		langSession.get('sessionLanguage'),
		request,
	)
	language = getLanguageFromCookieOrBrowserOrFallback(language, request)
	const userId = await getUserId(request)

	// Fetch navigation data with the determined language
	const navigationStoryblok: ISbStories = await storyBlokApi.getStories({
		starts_with: 'navigation',
		language: isAcceptedLanguage(language) ? language : 'da',
		version: isPreview() ? 'draft' : 'published',
	})
	const mobileNavCampaigns = await storyBlokApi.getStories({
		starts_with: 'campaigns/mobile-nav-campaigns/',
		language: isAcceptedLanguage(language) ? language : 'da',
		version: isPreview() ? 'draft' : 'published',
	})
	const discounts = await storyBlokApi.getStories({
		starts_with: 'discounts/',
		language: isAcceptedLanguage(language) ? language : 'da',
		version: isPreview() ? 'draft' : 'published',
	})

	const cookieHeader = request.headers.get('Cookie')
	const cookies = parseCookie(cookieHeader || '')
	const bannerVisible = cookies.bannerVisible !== 'false'
	const url = new URL(request.url)
	const pathSegments = url.pathname.split('/')

	// Extract category key from the URL
	let categoryKey = 'kids' // Default value
	const possibleCategories = ['kids', 'women', 'men']
	for (const segment of pathSegments) {
		const cleanSegment = segment.split('-')[0].toLowerCase()
		if (possibleCategories.includes(cleanSegment)) {
			categoryKey = cleanSegment
			break
		}
	}

	// Fetch products on sale data
	const [onSaleWomen, onSaleMen, onSaleKids] = await Promise.all([
		getProductsOnSale('women', visitorId),
		getProductsOnSale('men', visitorId),
		getProductsOnSale('kids', visitorId),
	])

	// Prepare navigation data
	const navigation: NavigationCategoriesStoryblok[] =
		navigationStoryblok.data.stories.map((story: any) => story.content)

	let announcementData: ISbStories = await storyBlokApi.getStories({
		starts_with: 'announcement-banner',
		language: isAcceptedLanguage(language) ? language : 'da',
		version: isPreview() ? 'draft' : 'published',
	})
	let searchData: ISbStories = await storyBlokApi.getStories({
		starts_with: 'search',
		language: isAcceptedLanguage(language) ? language : 'da',
		version: isPreview() ? 'draft' : 'published',
	})
	const announcementContent =
		announcementData.data.stories.length > 0
			? announcementData.data.stories[0].content
			: null
	// Return the data to the client
	return json({
		userId,
		onSaleWomen: onSaleWomen.count > 0,
		onSaleMen: onSaleMen.count > 0,
		onSaleKids: onSaleKids.count > 0,
		searchData,
		navigation,
		language, // This should now be Danish by default
		announcement: announcementContent,
		bannerVisible,
		AMPLITUDE_API_KEY: process.env.AMPLITUDE_API_KEY,
		categoryKey,
		mobileNavCampaigns: mobileNavCampaigns.data.stories,
		discounts: discounts.data.stories,
		// Adding campaigns data here
	})
}

export default function MainLayout() {
	const {
		userId,
		navigation,
		language,
		onSaleWomen,
		onSaleMen,
		onSaleKids,
		bannerVisible,
		announcement,
		AMPLITUDE_API_KEY,
	} = useLoaderData<typeof loader>()
	const { scrollY } = useScroll()
	const [hidden, setHidden] = useState(false)
	const isDesktop = useMediaQuery({ mobile: '820px' })
	const nodeRef = useRef<HTMLDivElement | null>(null)

	const location = useLocation()

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const utmParams: UTMParams = getUtmParamsFromReferrer(document.referrer)
			amplitude.init(AMPLITUDE_API_KEY as string, undefined, {
				serverZone: 'EU',
				defaultTracking: true,
				...utmParams,
			})
		}
	}, [AMPLITUDE_API_KEY])

	useEffect(() => {
		const handlePopState = () => {
			if (nodeRef.current && !isDesktop) {
				const currentPath = window.location.pathname
				if (currentPath.startsWith('/product/')) {
					nodeRef.current.style.setProperty('display', 'block', 'important')
				} else {
					nodeRef.current.style.setProperty('display', 'none', 'important')
				}
			}
		}

		if (!isDesktop) {
			window.onpopstate = handlePopState
		} else {
			window.onpopstate = () => {}
		}

		return () => {
			window.onpopstate = null
		}
	}, [isDesktop])

	useEffect(() => {
		nodeRef.current &&
			nodeRef.current.style.setProperty('display', 'block', 'important')
	}, [location.pathname])

	useMotionValueEvent(scrollY, 'change', latest => {
		const previous = scrollY.getPrevious()!
		if (latest > previous && latest > 168 && !isDesktop) {
			setHidden(true)
		} else {
			setHidden(false)
		}
	})

	return (
		<div>
			<motion.div
				className="sticky top-0 z-50"
				id="top"
				variants={{ visible: { y: 0 }, hidden: { y: '-100%' } }}
				animate={hidden ? 'hidden' : 'visible'}
				transition={{ duration: 0.35, ease: 'easeInOut' }}
			>
				<AnnouncementBanner
					announcement={announcement as AnnoncementStoryblok}
					bannerVisible={bannerVisible}
				/>
				<Navbar
					userId={userId}
					navigationData={navigation}
					sessionLanguage={language}
					onSaleWomen={onSaleWomen}
					onSaleMen={onSaleMen}
					onSaleKids={onSaleKids}
				/>
			</motion.div>
			<div id="bag-item-portal" />
			<div ref={nodeRef}>
				<Outlet />

				<div>
					<WhyShopHere />
					<Footer />
				</div>
			</div>
		</div>
	)
}

function getUtmParamsFromReferrer(referrer: string): UTMParams {
	try {
		if (!referrer) {
			return {}
		}
		const url = new URL(referrer)
		const params = new URLSearchParams(url.search)
		const utmParams: UTMParams = {}

		if (params.has('utm_source'))
			utmParams.utm_source = params.get('utm_source')!
		if (params.has('utm_medium'))
			utmParams.utm_medium = params.get('utm_medium')!
		if (params.has('utm_campaign'))
			utmParams.utm_campaign = params.get('utm_campaign')!
		if (params.has('utm_term')) utmParams.utm_term = params.get('utm_term')!
		if (params.has('utm_content'))
			utmParams.utm_content = params.get('utm_content')!
		return utmParams
	} catch (error) {
		console.error('Error parsing referrer URL:', error)
		return {}
	}
}
